import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@core/services/Loading/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loading-screen',
  standalone: true,
  imports: [AsyncPipe, NgOptimizedImage],
  templateUrl: './loading-screen.component.html',
  styleUrl: './loading-screen.component.scss',
})
export class LoadingScreenComponent implements OnInit {
  loading!: Observable<boolean>;

  constructor(private _loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loading = this._loadingService.isLoading$;
  }
}
