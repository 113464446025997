import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Service } from '@core/models/interfaces/servicesHub/services-hub';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServiceHubService {
  constructor(private __http: HttpClient) {}

  getAllServices(): Observable<Service[]> {
    return this.__http.get<Service>(`${environment.url}services`).pipe(
      map((res: any) => res.data)
    );
  }

  getServices(id:string): Observable<any>{
    return this.__http
    .get<any>(`${environment.url}service-pages/${id}?populate=*`)
    .pipe(
      map((res: any) => res.data)
    );
  }
}
