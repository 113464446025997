import {
  ApplicationConfig,
  ENVIRONMENT_INITIALIZER,
  inject,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import {
  provideAngularSvgIcon,
  SvgIconRegistryService,
} from 'angular-svg-icon';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { icons } from 'public/icons/icons';

const registerIconFactor = () => {
  const svgIconRegistryService = inject(SvgIconRegistryService);
  icons.forEach((icon) => {
    svgIconRegistryService.loadSvg(icon.url, icon.name);
  });
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      },

    )
    ),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideAnimationsAsync(),
    provideAngularSvgIcon(),
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => registerIconFactor(),
    },
  ],
};
