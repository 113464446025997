import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Solution,
  SolutionPage,
} from '@core/models/interfaces/solution/solution';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SolutionsService {
  constructor(private __http: HttpClient) {}

  getAllSolutions(): Observable<Solution[]> {
    return this.__http.get<Solution>(`${environment.url}solutions`).pipe(
      map((res: any) => res.data)
    );
  }

  getSolution(id: string) {
    return this.__http
      .get<SolutionPage>(`${environment.url}solution-pages/${id}?populate=*`)
      .pipe(map((res: any) => res.data));
  }
}
