<footer class="bg-primary-800">
  <section
    class="w-11/12 lg:w-85 mx-auto py-12 grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-4 text-white"
  >
    <!-- Logo and Company Info -->
    <article
      class="col-span-2 sm:col-span-2 flex justify-between align-center md:items-start md:justify-center sm:flex-col items-start gap-4"
    >
      <div class="w-2/12">
        <img
          class="sm:w-full mb-4"
          src="icons/logo-white.svg"
          alt="Certified IT Consultants Logo"
          title="SAP Gold Parter Egypt"
        />
      </div>
      <div class="flex gap-4 justify-center items-center">
        <a
          href="https://www.facebook.com/CICMENA/"
          title="cic sap gold partner - facebook link"
          target="_blank"
          class="w-4/12"
        >
          <svg-icon name="facebook"></svg-icon>
        </a>
        <a href="https://twitter.com/cicmena" target="_blank" class="w-4/12" title="cic sap gold partner - twitter link">
          <svg-icon name="twitter"></svg-icon>
        </a>
        <a
          href="https://www.linkedin.com/company/cicmena/mycompany/"
          title="cic sap gold partner - linkedin link"
          target="_blank"
          class="w-4/12"
        >
          <svg-icon name="linkedin"></svg-icon>
        </a>
      </div>
    </article>

    <!-- Navigation Links -->
    <article
      class="col-span-2 lg:col-span-1 hidden sm:flex justify-center gap-10"
    >
      <ul class="flex flex-col gap-2">
        <li>
          <a routerLink="/" class="font-medium text-sm uppercase"    title="cic sap gold partner - homepage">Home</a>
        </li>
        <li>
          <a routerLink="/services" class="font-medium text-sm uppercase"    title="cic sap gold partner - services"
            >Services</a
          >
        </li>
        <li>
          <a routerLink="/industry" class="font-medium text-sm uppercase"    title="cic sap gold partner - industries"
            >Industry</a
          >
        </li>
        <li>
          <a routerLink="/solutions" class="font-medium text-sm uppercase"    title="cic sap gold partner - solutions"
            >Solutions</a
          >
        </li>
        <!-- <li>
          <a routerLink="/projects" class="font-medium text-sm">Projects</a>
        </li> -->
      </ul>
      <ul class="flex flex-col gap-2">
        <li>
          <a routerLink="/partners" class="font-medium text-sm uppercase"
          title="cic sap gold partner - customers"
            >Customers</a
          >
        </li>
        <li>
          <a routerLink="/contact" class="font-medium text-sm uppercase"
          title="cic sap gold partner - contact"
            >Contact</a
          >
        </li>
        <li>
          <a routerLink="/about" class="font-medium text-sm uppercase" 
          title="cic sap gold partner BTP - contact"

          >About</a>
        </li>
        <li>
          <a
            routerLink="/innovation/about"
            class="font-medium text-sm uppercase"
            title="cic sap gold partner - btp innovation hub"
            >BTP</a
          >
        </li>
      </ul>
    </article>

    <!-- Contact Information -->
    <article
      class="col-span-2 sm:col-span-1 flex flex-col gap-5 sm:justify-between"
    >
      <div class="flex flex-col gap-4">
        <div class="flex gap-2 items-center">
          <svg-icon name="pin"></svg-icon>
          <a
            class="text-white text-sm"
            href="https://maps.app.goo.gl/61NRsSiFbnWawt268"
            target="_blank"
            title="CIC Branch Cairo, Egypt"
          >
            Cairo, Egypt
          </a>
        </div>

        <div class="flex gap-2 items-center">
          <svg-icon name="pin"></svg-icon>
          <a
            class="text-white text-sm"
            href="https://maps.app.goo.gl/U5z49pdf8zoS29Bi9"
            target="_blank"
            title="CIC Branch Riyadh, Saudi Arabia"
          >
            Riyadh, Saudi Arabia
          </a>
        </div>
        <div class="flex gap-2 items-center">
          <svg-icon name="pin"></svg-icon>
          <a
            class="text-white text-sm"
            href="https://maps.app.goo.gl/uMHktATHfFhnCu5N7"
            target="_blank"
            title="CIC Branch Abu Dhabi, United Arab Emirates"
          >
            Abu Dhabi, United Arab Emirates
          </a>
        </div>
      </div>
      <div class="flex gap-5 flex-col">
        <div class="flex gap-2 items-center">
          <svg-icon name="mail-icon"></svg-icon>
          <a
            href="mailto:sales@cic.ae"
            class="text-primary-200 text-sm"
            title="cic sap btp email"
          >
            sales&#64;cic.ae
          </a>
        </div>
        <div class="flex gap-2 items-center">
          <svg-icon name="phone-icon"></svg-icon>
          <span class="text-primary-200 text-sm">
            +20224142501 / +20224142502
          </span>
        </div>
      </div>
    </article>
  </section>

  <!-- Copyright -->
  <section
    class="bg-primary-900 flex justify-center items-center py-4 text-xs font-light"
  >
    <span class="text-white">©CIC 2025, ALL RIGHTS RESERVED.</span>
  </section>
</footer>
