// icons.ts
export const icons = [
  {
    name: 'facebook',
    url: 'icons/facebook.svg',
  },
  { name: 'globe', url: 'icons/globe.svg' },
  { name: 'globe_white', url: 'icons/globe_white.svg' },
  { name: 'paper-plane-blue', url: 'icons/paper-plane-blue.svg' },
  { name: 'paper-plane', url: 'icons/paper-plane.svg' },
  { name: 'linkedin', url: 'icons/linkedin.svg' },
  { name: 'location', url: 'icons/location.svg' },
  { name: 'twitter', url: 'icons/twitter.svg' },
  { name: 'twitter', url: 'icons/twitter.svg' },
  { name: 'logo', url: 'icons/logo.svg' },
  { name: 'logoWhite', url: 'icons/logo-white.svg' },
  { name: 'web-analysis', url: 'icons/web-analysis.svg' },
  { name: 'cloud-settings', url: 'icons/cloud-settings.svg' },
  { name: 'reporting', url: 'icons/reporting.svg' },
  { name: 'phone', url: 'icons/phone.svg' },
  { name: 'email', url: 'icons/inbox.svg' },
  { name: 'location-primary', url: 'icons/location-primary.svg' },
  { name: 'company-logo', url: 'icons/company-logo.svg' },
  { name: 'tmg', url: 'icons/tmg-logo.svg' },
  { name: 'maketing_idea', url: 'icons/maketing_idea.svg' },
  { name: 'mail-icon', url: 'icons/mail-icon.svg' },
  { name: 'phone-icon', url: 'icons/phone-icon.svg' },
  { name: 'pin', url: 'icons/pin-location.svg' },
  { name: 'feature', url: 'icons/favorite.svg' },


  // Add other icons here
];
