<header class="absolute z-10 w-full">
  <nav
    class="flex flex-wrap w-full lg:w-85 md:mx-auto py-3 px-4 justify-center md:mt-4 md:px-8 bg-white md:rounded-full"
  >
    <div
      class="order-2 grow md:grow-0 md:order-1 flex justify-center md:justify-start items-center"
    >
      <a routerLink="" title="CIC | Home Page"
      >
        <svg-icon name="logo"></svg-icon>
      </a>
    </div>
    <ul
      class="hidden md:flex grow order-2 md:justify-center md:gap-3 xl:gap-7 flex-wrap items-center mx-3"
    >
    @for(route of routes; track route){
      <li>
        <a
        [class.active_link]="isActive(route.route)"
          class="text-gray-700 text-md"
          [routerLink]="route.route"
          title="CIC | Home Page"
          >{{route.text}}</a
        >
      </li>
    }

    </ul>
    <div class="order-3 hidden md:flex items-center">
      <a
        routerLink="innovation"
        class="font-semibold text-gray-700 text-md"
        routerLinkActive="active_link"
        title="CIC | BTP Innovation"
        >BTP <span class="text-primary-600">Innovation Hub</span></a
      >
    </div>
    <div class="order-1 md:hidden items-center flex">
      <app-side-bar />
    </div>
  </nav>
</header>
