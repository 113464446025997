export const SEO_CONFIG: Partial<Record<string, { title: string; description: string; keywords: string; canonical: string }>> = {
  '/': {
    title: 'Home - CIC | SAP Gold Partner BTP in Egypt | KSA | UAE',
    description: 'Welcome to CIC, a SAP Gold Partner delivering ERP, S/4HANA, FICO, MM, SD, and BTP solutions in Egypt, KSA, and UAE. We empower businesses with innovative cloud technologies, enterprise tools, and SAP systems to foster sustainable growth, operational efficiency, and digital transformation.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Home, Digital Transformation, Cloud Technology, SAP Gold Partner, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/',
  },
  '/services': {
    title: 'Services - CIC | SAP Gold Partner & BTP in Egypt | KSA | UAE',
    description: 'Explore CIC’s professional services as a SAP Gold Partner, providing ERP, S/4HANA, FICO, MM, SD, and BTP solutions in KSA, Egypt, and UAE. We help businesses achieve innovation, digital transformation, and growth through advanced SAP tools, cloud technology, and industry-specific expertise.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Services, Cloud Solutions, SAP Gold Partner, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services',
  },
  '/solutions': {
    title: 'Solutions - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'Discover CIC’s tailored ERP, S/4HANA, FICO, MM, SD, and BTP solutions. As a SAP Gold Partner in UAE, KSA, and Egypt, we deliver cloud technologies and SAP innovations to transform businesses, boost operational efficiency, and ensure sustainable success across various industries.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions',
  },

  '/partners': {
    title: 'Partners - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'CIC collaborates with global innovators to deliver ERP, S/4HANA, FICO, MM, SD, and BTP solutions. As a SAP Gold Partner in Egypt, KSA, and UAE, our extensive partner network drives growth, enhances business efficiency, and fosters innovation through advanced SAP tools and technologies.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Partners, SAP Gold Partner, Innovation, Collaboration, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners',
  },
  '/industry': {
    title: 'Industries - CIC | SAP Gold Partner & BTP in Egypt | KSA | UAE',
    description: 'CIC offers industry expertise with ERP, S/4HANA, FICO, MM, SD, and BTP technologies. As a SAP Gold Partner in Egypt, KSA, and UAE, we empower businesses with innovative SAP solutions that enhance efficiency, drive growth, and address specific market challenges with tailored strategies.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Industry Expertise, SAP Gold Partner, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/industry',
  },
  '/about': {
    title: 'About Us - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'Learn about CIC, a SAP Gold Partner serving Egypt, KSA, and UAE. Specializing in ERP, S/4HANA, FICO, MM, SD, and BTP solutions, we empower businesses with advanced SAP tools to drive innovation, improve operational efficiency, and achieve sustainable growth and digital transformation.',
    keywords: 'Egypt, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, About Us, SAP Gold Partner, Innovation, Growth, KSA, UAE',
    canonical: 'https://cic.ae/about',
  },
  '/contact': {
    title: 'Contact Us - CIC | SAP Gold Partner & BTP in KSA | Egypt | UAE',
    description: 'Reach out to CIC, a SAP Gold Partner specializing in ERP, S/4HANA, FICO, MM, SD, and BTP technologies. Serving Egypt, KSA, and UAE, our team delivers tailored SAP solutions to help businesses achieve operational excellence, digital transformation, and sustainable growth.',
    keywords: 'UAE, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Contact, SAP Gold Partner, Support, Growth, Egypt, KSA',
    canonical: 'https://cic.ae/contact',
  },
  '/innovation/about': {
    title: 'BTP Business Technology Platform - SAP BTP in Egypt | KSA | UAE',
    description: 'CIC leads innovation as a SAP Gold Partner in Egypt, KSA, and UAE. Our expertise in ERP, S/4HANA, FICO, MM, SD, and BTP technologies empowers businesses to achieve sustainable growth, operational excellence, and market leadership through advanced SAP tools and digital transformation strategies.',
    keywords: 'KSA, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Innovation, SAP Gold Partner, Growth, Egypt, UAE',
    canonical: 'https://cic.ae/innovation/about',
  },

  '/solutions/obs47zh7fgb99ily0u9ssm9q': {
    title: 'SESMI Solution - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'SESMI revolutionizes service management by providing end-to-end control and visibility across departments, ensuring seamless operations from planning to payment.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/obs47zh7fgb99ily0u9ssm9q',
  },

  '/solutions/ufnbqc05nniinsac2ypmy5sn': {
    title: 'XCCRE Solution - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'xCCRE is a cutting-edge application built on SAP’s Real Estate Engine, utilizing the latest Fiori interface technologies for seamless access anytime, anywhere, and on any device.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/ufnbqc05nniinsac2ypmy5sn',
  },
  '/solutions/hb03h84pygrh107e0j3klpqd': {
    title: 'Refx for public cloud - CIC | SAP Gold Partner',
    description: 'Refx for Public Cloud is a powerful real estate management application that utilizes SAP’s Fiori design, while operating independently on a local database. This combination offers flexibility and robust functionality, along with a user-friendly interface for improved accessibility.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/hb03h84pygrh107e0j3klpqd',
  },
  '/solutions/amej1rxne2uyvkifh8ixa10e': {
    title: 'HCM for Public Sector - CIC | SAP Gold Partner',
    description: 'The HCM for Public Sector solution is designed to streamline human resources processes, ensuring effective management of personnel within government and public sector organizations. It focuses on organizing internal structures, monitoring performance, and maintaining up-to-date employee records.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/amej1rxne2uyvkifh8ixa10e',
  },
  '/solutions/lw25xjkq4be1x3edtqf3ybic': {
    title: 'Retirement Fund - CIC | SAP Gold Partner',
    description: 'The Retirement Fund Solution simplifies and automates end-of-service processes, improving efficiency, accuracy, and transparency, making it an essential tool for managing employee benefits.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/lw25xjkq4be1x3edtqf3ybic',
  },
  '/solutions/sub35wzrre6xv6yh3j3tyt6j': {
    title: 'Salesforce Integration - SAP Gold Partner & BTP',
    description: 'This Salesforce and SAP integration offers real estate companies a powerful tool for managing inventory, sales, and after-sales services, improving operational efficiency and providing a seamless experience for users.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/sub35wzrre6xv6yh3j3tyt6j',
  },
  '/solutions/jb5pg8iwqxaqyqrillidz0cm': {
    title: 'SESMI SD (Payment Application) - CIC | SAP Gold Partner',
    description: 'An advanced add-on for SAP ERP, designed to enhance the management of customer service entry sheets. It offers an efficient way to track and manage the progress of on-site work as maintained by the site engineer, ensuring seamless integration with various departments for accurate project tracking and billing.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/jb5pg8iwqxaqyqrillidz0cm',
  },
  '/solutions/at82vcokxuk75xofjh9rdhfo': {
    title: 'Capex Approval App - CIC | SAP Gold Partner in UAE | KSA | Egypt',
    description: 'Discover CIC’s tailored ERP, S/4HANA, FICO, MM, SD, and BTP solutions. The Capex Approval App streamlines the capital expenditure process by automating the creation, approval, and tracking of DNs',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/at82vcokxuk75xofjh9rdhfo',
  },
  '/solutions/krlikbzb5b1gkprw3iubjrbr': {
    title: 'Customer Credit App - CIC | SAP Gold Partner',
    description: 'Discover CIC’s tailored ERP, S/4HANA, FICO, MM, SD, and BTP solutions. This solution optimizes credit management by enhancing transparency, operational control, and financial oversight.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/krlikbzb5b1gkprw3iubjrbr',
  },
  '/solutions/xekwmgq4s42lnu3n9ntddclk': {
    title: 'Collection Forecast - CIC | SAP Gold Partner',
    description: 'The Collection Forecast tool uses AI-driven analytics to project cash flow, monitor customer payment behaviors, and enhance financial planning in the real estate sector.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/xekwmgq4s42lnu3n9ntddclk',
  },
  '/solutions/mgeabpkzgy651hjvwx4eassz': {
    title: 'Bank ReconMan - CIC | SAP Gold Partner in UAE | KSA | Egypt',
    description: 'A cutting-edge solution designed to simplify and enhance bank reconciliation processes through automation and machine learning.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/mgeabpkzgy651hjvwx4eassz',
  },
  '/solutions/qexgemgf11j940wa7huvcntg': {
    title: 'ISPIR Solution - CIC | SAP Gold Partner in UAE | KSA | Egypt',
    description: 'An integrated, internet-based solution designed for online spare parts data collection, review, and approval, leveraging the electronic version of the Spare Parts Interchangeability Record (SPIR).',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/qexgemgf11j940wa7huvcntg',
  },
  '/solutions/txof867dyyhx479sntxxzwhi': {
    title: 'AI-Powered Language Solutions & Gen AI - CIC | SAP Gold Partner',
    description: 'This Generative AI in SAP BTP solution leverages powerful AI technologies to drive smarter business decisions, enhance customer interactions, and provide real-time insights—helping companies stay ahead in an ever-evolving market.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/txof867dyyhx479sntxxzwhi',
  },
  '/solutions/vggi7zhtepqubei1zh3vvpgk': {
    title: 'Estimation Solution - CIC | SAP Gold Partner in KSA | Egypt',
    description: 'A cornerstone of effective project management and financial planning. It ensures that projects stay on track and within budget by accurately forecasting costs, resources, and timelines.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/vggi7zhtepqubei1zh3vvpgk',
  },
  '/solutions/dn8q211r4ffjm5j0yznn8luc': {
    title: 'Sourcing MOD (Tendering) - CIC | SAP Gold Partner in UAE',
    description: 'Tendering is a formal procurement process where contractors, suppliers, or external consultants are invited by official entities or companies to submit bids for goods, services, or works. The aim is to select the best bidder based on pre-determined criteria.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/dn8q211r4ffjm5j0yznn8luc',
  },
  '/solutions/xo0dmn0dxibl0r21hmdz2h5m': {
    title: 'DBM Enhancement Solution - CIC | SAP Gold Partner in Egypt | KSA | UAE',
    description: 'This enhancement helps businesses optimize their vehicle purchasing process, improve planning accuracy, and streamline cost management.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/xo0dmn0dxibl0r21hmdz2h5m',
  },
  '/solutions/dwgpo4nwkr6uozstpgeisubd': {
    title: 'AR/AP Made Easy Solution - CIC | SAP Gold Partner in Egypt | KSA | UAE',
    description: 'CIC’s AR/AP Automation Solution simplifies and accelerates accounts receivable (AR) and accounts payable (AP) processes, providing a seamless experience across web, tablet, and mobile platforms.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/dwgpo4nwkr6uozstpgeisubd',
  },
  '/solutions/dn7sflqkkuwpicy3sua6aayz': {
    title: 'Cash Budget Solution - CIC | SAP Gold Partner in UAE | KSA | Egypt',
    description: 'CIC’s Cash Budget Solution streamlines budget management, offering organizations full control over planning, approval, and consumption through intuitive features and tools.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/dn7sflqkkuwpicy3sua6aayz',
  },
  '/solutions/m0ke0pxp9mesr6k9mayh55ut': {
    title: 'Project Control Boardroom - SAP Gold Partner in KSA | UAE | Egypt',
    description: 'Our reporting solution delivers dynamic insights into essential business metrics such as sales, revenue, payment plans, and Built-Up Area (BUA) through intuitive visualizations like charts and pie charts. These tools empower users to quickly identify trends and performance, supporting data-driven decision-making.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/m0ke0pxp9mesr6k9mayh55ut',
  },
  '/solutions/avca6gbv67e964vu4opxao4v': {
    title: 'Primavera Integration Add-On Solution - CIC | SAP Gold Partner',
    description: 'The Primavera Integration Add-On for SAP enhances project management capabilities by seamlessly connecting SAP with Primavera.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/avca6gbv67e964vu4opxao4v',
  },
'/solutions/lpxxff9la12t84ali0ehjgf3': {
    title: 'Project Portfolio Management Solution - CIC | SAP Gold Partner',
    description: 'CIC’s PPM (Project Portfolio Management) solution simplifies and optimizes project and portfolio management by providing a comprehensive, automated platform for all project phases.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/lpxxff9la12t84ali0ehjgf3',
  },
  '/solutions/fyimby0thg5sicwmtxbkwxep': {
    title: 'E-Service Solution - CIC | SAP Gold Partner in Egypt | KSA | UAE',
    description: 'Discover CIC’s tailored ERP, S/4HANA, FICO, MM, SD, and BTP solutions.Digital platforms have redefined customer service in the real estate industry',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/fyimby0thg5sicwmtxbkwxep',
  },
  '/solutions/ljfyxk8fc0j5xcegiee5u6xn': {
    title: 'RE Mortage (NUCA) - CIC | SAP Gold Partner',
    description: 'A comprehensive web application designed to streamline long-term financing for real estate units. It delivers a seamless and efficient experience for both applicants and administrators, combining advanced features with user-centric design.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/ljfyxk8fc0j5xcegiee5u6xn',
  },
  '/solutions/u5q62wva92ay5cy6ollssgn0': {
    title: 'VSO Solution - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'VSO is a cutting-edge e-commerce platform designed to enhance the real estate sales process. Its user-friendly interface, secure payment options, and seamless ERP integration make it the ideal tool for businesses and developers to optimize sales and customer engagement.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/u5q62wva92ay5cy6ollssgn0',
  },
  '/solutions/ckw421i79pc3vlxmclwsa9ve': {
    title: 'Mobile Van Sales - CIC | SAP Gold Partner in UAE',
    description: 'Our solution empowers mobile sales teams to manage all tour-related activities seamlessly from their mobile devices, streamlining daily tasks and enhancing efficiency for drivers on the go.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/ckw421i79pc3vlxmclwsa9ve',
  },
  '/solutions/n7oa8ubp17ardrjdfsk7bg04': {
    title: 'Mobile Inventory And Warehouse Management - SAP Gold Partner',
    description: 'Transform your warehouse operations with real-time insights and mobile convenience. Boost efficiency, increase productivity, and bring accuracy to the forefront of your business with our integrated mobile solution.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/n7oa8ubp17ardrjdfsk7bg04',
  },
'/solutions/dgqrwo5lkgmy18f73uj94fsf': {
    title: 'ManPower Supply - CIC | SAP Gold Partner in Egypt',
    description: 'Discover CIC’s tailored ERP, S/4HANA, FICO, MM, SD, and BTP solutions. As a SAP Gold Partner in UAE, KSA, and Egypt, we deliver cloud technologies and SAP innovations to transform businesses, boost operational efficiency, and ensure sustainable success across various industries.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/dgqrwo5lkgmy18f73uj94fsf',
  },
  '/solutions/v9c39fyaf5txufxndne5dm45': {
    title: 'E-Invoicing (KSA) - CIC | SAP Gold Partner & BTP in Saudi Arabia',
    description: 'Our Solution manages the entire manpower cycle—from initial inquiry to billing—by automating, optimizing, and simplifying operations.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/v9c39fyaf5txufxndne5dm45',
  },
  '/solutions/i85azk6n1f2ugjsnvo7grgqb': {
    title: 'E-Invoicing (Egypt) - CIC | SAP Gold Partner in UAE',
    description: 'With CIC’s E-Invoicing Solution, your business can confidently meet all regulatory requirements while enjoying a secure, efficient, and hassle-free invoicing process.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/i85azk6n1f2ugjsnvo7grgqb',
  },
  '/solutions/k9db3n65u3t4rvwkj5hrmzo1': {
    title: 'Customer Payment Portal (City pay) - SAP Gold Partner',
    description: 'Transform your payment process with CIC’s Customer Payment Portal—making payments easy, secure, and efficient while delivering a superior user experience.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/k9db3n65u3t4rvwkj5hrmzo1',
  },


};
