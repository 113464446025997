import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { navbarData } from './data/nav-route';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink,RouterLinkActive,SvgIconComponent,SideBarComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  get routes (){
    return navbarData;
  }

  constructor(private router: Router) {}

  isActive(route: string): boolean {
    return this.router.url === `/${route}`;
  }
}
