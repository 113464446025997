import { Component, OnInit, Renderer2 } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet
} from '@angular/router';
import { HeaderComponent, FooterComponent } from './layout';
import { LoadingScreenComponent } from '@shared/components/loading-screen/loading-screen.component';
import { LoadingService } from '@core/services/Loading/loading.service';
import { Meta, Title } from '@angular/platform-browser';
import { SEO_CONFIG } from '@shared/seo-config';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    LoadingScreenComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  host: {
    class: 'flex flex-col h-screen',
  },
})
export class AppComponent implements OnInit {
  constructor(
    public __loadingService: LoadingService,
    private router: Router,
    private metaService: Meta,
    private titleService: Title,
    private renderer: Renderer2 // Add Renderer2 for DOM manipulation
  ) {}

  ngOnInit(): void {
    this.loadingScreen();
    this.setDynamicMetaTags();
  }

  loadingScreen() {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
            this.__loadingService.showLoading();
        } else {
          setTimeout(()=>{
            this.__loadingService.hideLoading();
          },600)
        }
      });
  }

  setDynamicMetaTags() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const currentRoute = this.router.url.split('?')[0]; // Remove query params if any
      const seoData = SEO_CONFIG[currentRoute];

      if (seoData) {
        // Set page title
        this.titleService.setTitle(seoData.title);

        // Set meta tags
        this.metaService.updateTag({ name: 'description', content: seoData.description });
        this.metaService.updateTag({ name: 'keywords', content: seoData.keywords });

        // Set or update canonical tag
        this.updateCanonicalTag(seoData.canonical);

        // Optional: Update Open Graph tags if needed
        this.metaService.updateTag({ property: 'og:title', content: seoData.title });
        this.metaService.updateTag({ property: 'og:description', content: seoData.description });
      }
    });
  }

  updateCanonicalTag(canonicalUrl: string) {
    // Check if the canonical link tag already exists
    let link: HTMLLinkElement | null = document.querySelector("link[rel='canonical']");
    if (!link) {
      // Create a new link element if it doesn't exist
      link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'canonical');
      this.renderer.appendChild(document.head, link);
    }
    // Update the href attribute
    this.renderer.setAttribute(link, 'href', canonicalUrl);
  }
}
