export const navbarData = [
  {
    route: '',
    text: 'Home',
  },
  {
    route: 'services',
    text: 'Services',
  },
  {
    route: 'solutions',
    text: 'Solutions',
  },
  {
    route: 'industry',
    text: 'Industry',
  },
  {
    route: 'partners',
    text: 'Customers',
  },
  {
    route: 'about',
    text: 'About',
  },
  {
    route: 'contact',
    text: 'Contact',
  }
]
