import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Routes } from '@angular/router';
import { ServiceHubService } from '@core/services/api/serviceHub/service-hub.service';
import { SolutionsService } from '@core/services/api/solutions/solutions.service';
import { catchError, of} from 'rxjs';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '' },
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.component').then((c) => c.HomeComponent),
  },
  {
    path: 'services',
    loadComponent: () =>
      import('./pages/services/services.component').then(
        (c) => c.ServicesComponent
      ),
  },
  {
    path: 'services/:id',
    loadComponent: () =>
      import('./pages/services/view/service/service.component').then(
        (c) => c.ServiceComponent
      ),
      resolve: {
        service: (route: ActivatedRouteSnapshot) => {
          const router = inject(Router);
          return inject(ServiceHubService).getServices(route.params['id']).pipe(
            catchError(() => {
              router.navigate(['/services']);
              return of(null);
            })
          );
        },
      }

  },
  {
    path: 'solutions',
    loadComponent: () =>
      import('./pages/solutions/solutions.component').then(
        (c) => c.SolutionsComponent
      ),
  },
  {
    path: 'solutions/:id',
    loadComponent: () =>
      import(
        './pages/solutions/view/solution-details/solution-details.component'
      ).then((c) => c.SolutionDetailsComponent),
    resolve: {
      solution: (route: ActivatedRouteSnapshot) => {
        const router = inject(Router);
        return inject(SolutionsService).getSolution(route.params['id']).pipe(
          catchError(() => {
            router.navigate(['/solutions']);
            return of(null);
          })
        );
      },
    },
  },
  {
    path: 'industry',
    loadComponent: () =>
      import('./pages/industry/industry.component').then(
        (c) => c.IndustryComponent
      ),
  },
  {
    path: 'partners',
    loadComponent: () =>
      import('./pages/partners/partners.component').then(
        (c) => c.PartnersComponent
      ),
  },
  // {
  //   path: 'partners/:id',
  //   loadComponent: () =>
  //     import(
  //       './pages/partners/view/partner-internal/partner-internal.component'
  //     ).then((c) => c.PartnerInternalComponent),
  // },
  // {
  //   path: 'projects',
  //   loadComponent: () =>
  //     import('./pages/projects/projects.component').then(
  //       (c) => c.ProjectsComponent
  //     ),
  // },
  // {
  //   path: 'projects/:id',
  //   loadComponent: () =>
  //     import('./pages/projects/view/project/project.component').then(
  //       (c) => c.ProjectComponent
  //     ),
  // },
  {
    path: 'about',
    loadComponent: () =>
      import('./pages/about/about.component').then((c) => c.AboutComponent),
  },
  {
    path: 'contact',
    loadComponent: () =>
      import('./pages/contact/contact.component').then(
        (c) => c.ContactComponent
      ),
  },
  {
    path: 'innovation',
    loadComponent: () =>
      import('./pages/innovation-btp-hub/innovation-btp-hub.component').then(
        (c) => c.InnovationBtpHubComponent
      ),
    children: [
      { path: '', redirectTo: 'about', pathMatch: 'full' },
      {
        path: 'about',
        loadComponent: () =>
          import('./pages/innovation-btp-hub/view/about/about.component').then(
            (c) => c.AboutComponent
          ),
      },
      // {
      //   path: 'department',
      //   loadComponent: () =>
      //     import(
      //       './pages/innovation-btp-hub/view/department/department.component'
      //     ).then((c) => c.DepartmentComponent),
      // },
      // {
      //   path: 'solutions',
      //   loadComponent: () =>
      //     import(
      //       './pages/innovation-btp-hub/view/solutions/solutions.component'
      //     ).then((c) => c.SolutionsComponent),
      // },
    ],
  },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];
