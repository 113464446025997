@if(loading | async){
<div
  class="loading-overlay flex justify-center items-center fixed h-dvh w-full z-50"
>
  <div class="logo-loader">
    <img
      fill
      priority
      ngSrc="icons/logo-white.svg"
      alt="CIC Logo | SAP Partner"
      class="logo"
    />
  </div>
</div>

}
