<button title="menu" (click)="toggleSidenav()">
  <span class="nav-btn__overlay">
    <i class="nav-btn__icon pi pi-bars text-2xl text-primary"></i>
  </span>
</button>
<p-sidebar
  [(visible)]="showSideBar"
  position="left"
  styleClass="bg-semi-transparent"
  [fullScreen]="true"
  [showCloseIcon]="false"
>
  <ng-template pTemplate="header">
    <div class="flex w-full lg:w-85 mx-auto px-2">
      <div class="flex">
        <button title="close" (click)="toggleSidenav()">
          <i class="nav-btn__icon pi pi-times text-xl text-primary"></i>
        </button>
      </div>
      <div class="grow">
        <a
          title="logo"
          title="CIC | Home Page"
          routerLink="/"
          class="flex justify-center lg:justify-start items-center w-fit mx-auto"
        >
          <figure>
            <svg-icon name="logo"></svg-icon>
          </figure>
        </a>
      </div>
      <div class="flex"></div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ul class="flex flex-col items-start justify-start h-full ml-6 gap-10 mt-24 font-medium">
      <li class="flex justify-center">
        <a
          class="text-2xl text-black"
          routerLink="home"
          title="CIC | Home Page"
          routerLinkActive="active_link"
          (click)="toggleSidenav()"
          >Home</a
        >
      </li>
      <li class="flex justify-center">
        <a
        class="text-2xl text-black"
        routerLink="services"
          title="CIC | Services"
          routerLinkActive="active_link"
          (click)="toggleSidenav()"
          >Services</a
        >
      </li>
      <li class="flex justify-center">
        <a
        class="text-2xl text-black"
        routerLink="solutions"
          title="CIC | Solutions"
          routerLinkActive="active_link"
          (click)="toggleSidenav()"
          >Solutions</a
        >
      </li>
      <li class="flex justify-center">
        <a
        class="text-2xl text-black"
        routerLink="industry"
          title="CIC | Industry"
          routerLinkActive="active_link"
          (click)="toggleSidenav()"
          >Industry</a
        >
      </li>
      <li class="flex justify-center">
        <a
        class="text-2xl text-black"
        routerLink="partners"
          title="CIC | Customers"
          routerLinkActive="active_link"
          (click)="toggleSidenav()"
          >Customers</a
        >
      </li>
      <!-- <li class="flex justify-center">
        <a
          class="text-lg"
          routerLink="projects"
          routerLinkActive="active_link"
          (click)="toggleSidenav()"
          >Projects</a
        >
      </li> -->
      <li class="flex justify-center">
        <a
        class="text-2xl text-black"
        routerLink="about"
          title="CIC | About"
          routerLinkActive="active_link"
          (click)="toggleSidenav()"
          >About</a
        >
      </li>
      <li class="flex justify-center">
        <a
        class="text-2xl text-black"
        routerLink="contact"
          title="CIC | Contact"
          routerLinkActive="active_link"
          (click)="toggleSidenav()"
          >Contact</a
        >
      </li>
      <li class="flex justify-center font-semibold">
        <a
        class="text-2xl text-black"
        routerLink="innovation"
          title="CIC | BTP Innovation"
          routerLinkActive="active_link"
          (click)="toggleSidenav()"
          >Innovation <span class="text-primary-600">BTP Hub</span></a
        >
      </li>
    </ul>
  </ng-template>
  <ng-template pTemplate="footer">
    <div
      class="flex md:flex-row flex-col gap-6 mb-4 items-center justify-between px-main"
    ></div>
  </ng-template>
  <!-- landing page Navbar -->
</p-sidebar>
