export const environment = {
  production: true,
  url: 'https://cic-dev.eshtri-cluster-eu-de-1-bx-8f23923b84c5cec3cecb2d74397b77c3-0000.eu-de.containers.appdomain.cloud/api/'
  // preDevURL: '/api',
  // bankUrl:
  //   '',
  // bankUrl:
    // '',
  // loggerAPI: ''
};
